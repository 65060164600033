<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <h1 class="text-2xl font-bold">KYC Override Portal</h1>
      <div class="bg-blue-100 p-5">
        <p>
          Please note that this page only shows businesses whose KYC is false.
        </p>
      </div>
      <!-- <div class="flex justify-end items-end my-5">
        <button
          @click="downloadCSV"
          class="
                    block
                    uppercase
                    shadow
                    ml-2
                    text-sm
                    bg-blue-900
                    text-white
                    hover:bg-blue-600
                    focus:shadow-outline
                    focus:outline-none
                    py-3
                    px-8
                    rounded
                    
                  "
        >
          Download CSV
        </button>
      </div> -->
      <div class="flex items-center my-5 mt-10">
        <input
          type="text"
          class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
          name=""
          placeholder="Search by Business Name or Email Address"
          id=""
          v-model="search"
        />
        <button
          @click="handleSearch"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Search
        </button>
        <button
          @click="resetSearch"
          class="block uppercase shadow ml-2 text-sm bg-red-900 text-white hover:bg-red-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Reset
        </button>
      </div>
      <div class="my-5 flex justify-end items-end">
        <button
          @click="fetchAll"
          class="block uppercase shadow ml-3 text-xs hover:bg-blue-700 focus:shadow-outline focus:outline-none hover:text-white py-3 px-8 rounded"
          :class="
            notKYCFlag === false && approvedKYCFlag === false
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          All
        </button>
        <button
          @click="notKYC"
          class="block uppercase shadow ml-3 hover:bg-blue-700 hover:text-white focus:shadow-outline focus:outline-none text-black text-xs py-3 px-8 rounded"
          :class="
            notKYCFlag === true
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          Not Overidden
        </button>
        <button
          @click="approvedKYC"
          class="block uppercase shadow ml-3 hover:bg-blue-700 focus:shadow-outline hover:text-white focus:outline-none text-black text-xs py-3 px-8 rounded"
          :class="
            approvedKYCFlag === true
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          Overidden
        </button>
      </div>
      <button
        class="bg-gray-300 text-black text-xs py-3 px-6 rounded-md mr-3 text-black mb-3 font-semibold"
        @click="overrideKYCSelected"
      >
        Doc Override Selected ({{ selected.length }})
      </button>
      <button
        class="bg-black text-xs py-3 px-6 rounded-md text-white mb-3 font-semibold"
        @click="unOverrideKYCSelected"
      >
        Doc Un-Override Selected ({{ selected.length }})
      </button>
      <button
        class="bg-yellow-300 ml-2 text-black text-xs py-3 px-6 rounded-md mr-3 text-black mb-3 font-semibold"
        @click="overrideBVNSelected"
      >
        BVN Override Selected ({{ selected.length }})
      </button>
      <button
        class="bg-black text-xs py-3 px-6 rounded-md text-white mb-3 font-semibold"
        @click="unOverrideBVNSelected"
      >
        BVN Un-Override Selected ({{ selected.length }})
      </button>
      <div class="mt-5" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="14"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              <input type="checkbox" v-model="selectAll" />
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business Name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Merchant name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Phone Number
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Email Address
            </th>

            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Override Status
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              KYC Status
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Action
            </th>
          </tr>
          <tr v-for="(account, i) in filterAccounts" :key="i">
            <td>
              <input
                type="checkbox"
                :value="account.business_id"
                v-model="selected"
              />
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.business_name || "N/A" | truncate(30, "...") }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.merchant_name }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.phone_number }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.email }}
            </td>
            <td
              class="border px-8 py-3 whitespace-nowrap text-xs"
              :class="
                account.hasKycOverride ? 'text-green-500' : 'text-red-500'
              "
            >
              {{ account.hasKycOverride ? "Overidden" : "Not Overidden" }}
            </td>
            <td
              class="border px-8 py-3 whitespace-nowrap text-xs"
              :class="account.kyc_status ? 'text-green-500' : 'text-red-500'"
            >
              {{ account.kyc_status ? "Approved" : "Not Approved" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <button
                v-if="!account.hasKycOverride"
                @click="overrideKYC(account.business_id)"
                class="block uppercase shadow ml-3 hover:bg-white bg-red-200 focus:shadow-outline focus:outline-none text-black text-xs font-bold py-3 px-8 rounded"
              >
                Override Doc
              </button>
              <button
                @click="unoverrideKYC(account.business_id)"
                v-else
                class="block uppercase shadow ml-3 bg-red-400 hover:bg-blue-700 hover:text-white focus:shadow-outline focus:outline-none text-black font-bold text-xs py-3 px-8 rounded"
              >
                Un-Override Doc
              </button>
              <button
                v-if="!account.hasKycOverride"
                @click="overrideBVN(account.business_id)"
                class="block mt-3 uppercase shadow ml-3 hover:bg-white bg-blue-200 focus:shadow-outline focus:outline-none text-black text-xs font-bold py-3 px-8 rounded"
              >
                Override BVN
              </button>
              <button
                @click="unoverrideBVN(account.business_id)"
                v-else
                class="block mt-3 uppercase shadow ml-3 bg-blue-700 hover:bg-blue-200 hover:text-black focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 rounded"
              >
                Un-Override BVN
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
          v-if="this.GET_FRAUD.data.items.length > 99"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      currentPage: 1,
      totalPages: 0,
      statusSort: "all",
      approvedKYCFlag: false,
      notKYCFlag: false,
      numberOfItems: 0,
      selected: [],
      selectedWhitelist: [],
      isDisabled: true,
    };
  },
  computed: {
    ...mapGetters(["GET_FRAUD"]),
    filterAccounts() {
      if (this.GET_FRAUD.data.items.length > 0) {
        return this.GET_FRAUD.data.items.filter(
          (account) =>
            account.business_id
              .toLowerCase()
              .includes(this.search.toLowerCase().trim()) ||
            account.business_name
              .toLowerCase()
              .includes(this.search.toLowerCase().trim()) ||
            account.merchant_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) ||
            account.email
              .toLowerCase()
              .includes(this.search.toLowerCase().trim()) ||
            account.phone_number
              .toLowerCase()
              .includes(this.search.toLowerCase().trim())
        );

        // return [...k].filter((x) => {
        //   return x.hasKycOverride === false;
        // });

        // if (this.statusSort !== "all") {
        //   return [...k].filter((x) => {
        //     return x.is_fraud === this.statusSort;
        //   });
        // } else {
        //   return k;
        // }
      }
    },
    // isDisabled() {
    //   if (this.currentPage === 1) return true;
    // },
    all() {
      return this.GET_FRAUD.data.items;
    },
    selectAll: {
      get: function() {
        return this.GET_FRAUD.data.items
          ? this.selected.length == this.GET_FRAUD.data.items.length
          : false;
      },
      set: function(value) {
        var selected = [];

        if (value) {
          if (this.GET_FRAUD.data.items !== null) {
            this.GET_FRAUD.data.items.forEach(function(account) {
              selected.push(account.business_id.toString());
            });
          }
        }

        this.selected = selected;
      },
    },
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async mounted() {
    // this.isLoading = true;
  },
  async created() {
    this.isLoading = true;
    let response = await this.$store.dispatch("FETCH_OVERRIDDEN_KYC_PORTAL");
    this.accounts = response;
    if (response.data) {
      this.isLoading = false;
      // this.totalPages = response.headers.Totalpages;
      // this.numberOfItems = response.headers.Numberofitems;
    }
    this.isLoading = false;
  },
  methods: {
    // ...mapGetters(["GET_FRAUD"]),
    async markAsFraudulent(id, status) {
      let res = confirm("Are you sure you want to perform this operation?");
      this.isLoading = true;
      let payload = [id, status];
      if (res) {
        let res = await this.$store.dispatch("UPDATE_FRAUD_STATUS", payload);
        if (res.status) {
          alert("Status was updated successfully");
          setTimeout(() => {
            this.$router.go();
          }, 200);
        } else {
          alert(res.error);
        }
      }
      this.isLoading = false;
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FRAUD",
        currPage - 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FRAUD",
        currPage + 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
    async handleSearch() {
      this.isLoading = true;
      try {
        let res = await this.$store.dispatch("FETCH_SINGLE_FRAUD", this.search);

        if (res.status) {
          this.isLoading = false;
        } else {
          alert("Could not find " + this.search);
          // console.log(res.data);
          // $router.go();
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
      }

      this.isLoading = false;
    },

    async resetSearch() {
      this.isLoading = true;
      let response = await this.$store.dispatch("FETCH_ALL_FRAUD");

      // console.log(this.accounts.items[32].bankAccounts[0].business_name);
      console.log(response.data.data);
      if (response.data.data) {
        this.isLoading = false;
      }
    },

    async downloadCSV() {
      this.isLoading = true;

      let res = await this.$store.dispatch("DOWNLOAD_FRAUD_CSV");

      if (res.status) {
        window.open(res.data, "_blank");
      } else {
        alert("Could not find " + this.search);
        // console.log(res.data);
      }
      this.isLoading = false;
    },
    async approvedKYC() {
      this.isLoading = true;

      this.approvedKYCFlag = true;
      this.notKYCFlag = false;

      let res = await this.$store.dispatch("FETCH_KYC_TYPE", "override");

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },
    async notKYC() {
      this.isLoading = true;
      this.notKYCFlag = true;
      this.approvedKYCFlag = false;

      let res = await this.$store.dispatch("FETCH_KYC_TYPE", "unoverride");

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },

    async fetchAll() {
      this.isLoading = true;
      this.notKYCFlag = false;
      this.approvedKYCFlag = false;

      let res = await this.$store.dispatch("FETCH_ALL_FRAUD");

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },

    async overrideKYC(id) {
      let res = confirm("Are you sure you want to override this account?");
      if (res === true) {
        this.isLoading = true;
        let payload = {
          businesses: [id],
        };
        try {
          let res = await this.$store.dispatch("OVERRIDE_KYC", payload);
          if (res.status == true) {
            alert("Override was successful");
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async unoverrideKYC(id) {
      let res = confirm("Are you sure you want to un-override this account?");
      if (res === true) {
        this.isLoading = true;
        let payload = {
          businesses: [id],
        };
        try {
          let res = await this.$store.dispatch("UNOVERRIDE_KYC", payload);
          if (res.status == true) {
            alert("Un-override was successful");
            this.$router.go();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async overrideBVN(id) {
      let res = confirm("Are you sure you want to override this account?");
      if (res === true) {
        this.isLoading = true;
        let payload = {
          businesses: [id],
        };
        try {
          let res = await this.$store.dispatch("OVERRIDE_BVN", payload);
          if (res.status == true) {
            alert("Override was successful");
            this.fetchAll();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async unoverrideBVN(id) {
      let res = confirm("Are you sure you want to un-override this account?");
      if (res === true) {
        this.isLoading = true;
        let payload = {
          businesses: [id],
        };
        try {
          let res = await this.$store.dispatch("UNOVERRIDE_BVN", payload);
          if (res.status) {
            alert("Un-override was successful");
            this.fetchAll();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async overrideKYCSelected() {
      let res = confirm("Are you sure you want to override these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          this.selectedWhitelist.push(el);
        });
        let payload = {
          businesses: this.selectedWhitelist,
        };
        try {
          let res = await this.$store.dispatch("OVERRIDE_KYC", payload);
          if (res.status == true) {
            alert("Override was successful");
            this.fetchAll();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async unOverrideKYCSelected() {
      let res = confirm("Are you sure you want to un-override these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          this.selectedWhitelist.push(el);
        });
        let payload = {
          businesses: this.selectedWhitelist,
        };
        try {
          let res = await this.$store.dispatch("UNOVERRIDE_KYC", payload);
          if (res.status == true) {
            alert("Un-override was successful");
            this.fetchAll();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async overrideBVNSelected() {
      let res = confirm("Are you sure you want to override these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          this.selectedWhitelist.push(el);
        });
        let payload = {
          businesses: this.selectedWhitelist,
        };
        try {
          let res = await this.$store.dispatch("OVERRIDE_BVN", payload);
          if (res.status == true) {
            alert("Override was successful");
            this.fetchAll();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
    async unOverrideBVNSelected() {
      let res = confirm("Are you sure you want to un-override these accounts?");
      if (res === true) {
        this.isLoading = true;
        this.selected.map((el) => {
          this.selectedWhitelist.push(el);
        });
        let payload = {
          businesses: this.selectedWhitelist,
        };
        try {
          let res = await this.$store.dispatch("UNOVERRIDE_BVN", payload);
          if (res.status == true) {
            alert("Un-override was successful");
            this.fetchAll();
          } else {
            alert(
              res.error ||
                res.message ||
                "Error: Please check network or contact admin."
            );
          }
        } catch (error) {}
      }
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
